
import Person from "~/graphql/Person/Person.gql";

import {
  PROPERTY_TYPE_MAIN_PROPERTY,
  PROPERTY_TYPE_CONDO,
  PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT,
  PROPERTY_TYPE_UNKNOWN,
  getPropertyType,
} from "~/helpers/property-helpers.js";

export default {
  inheritAttrs: false,

  apollo: {
    person: {
      query: Person,

      update(data) {
        return data.person;
      },

      error(error) {
        console.error("Person failed:", error);
      },

      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },

  computed: {
    loading() {
      return this.$apollo.queries.person.loading;
    },

    propertiesCount() {
      if (this.person.properties) {
        return this.person.properties.filter((p) => getPropertyType(p) == PROPERTY_TYPE_MAIN_PROPERTY).length;
      }

      return 0;
    },

    condosCount() {
      if (this.person.properties) {
        return this.person.properties.filter((p) => getPropertyType(p) == PROPERTY_TYPE_CONDO).length;
      }

      return 0;
    },

    bofgCount() {
      if (this.person.properties) {
        this.person.properties.filter((p) => getPropertyType(p) == PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT).length;
      }

      return 0;
    },

    unknownCount() {
      if (this.person.properties) {
        return this.person.properties.filter((p) => getPropertyType(p) == PROPERTY_TYPE_UNKNOWN).length;
      }

      return 0;
    },

    companyRelationsCount() {
      if (this.person.relations) {
        return this.person.relations.length;
      }

      return 0;
    },
  },

  methods: {
    navigateTo(path, query) {
      this.$amplitude.track({
        event_type: `nagivate from: overview-dashboard (person), to: ${path}`,
      });

      this.$router.push({
        name: path,
        query: query || {},
        params: {
          id: this.$route.params.id,
        },
      });
    },
  },
};
